<template>
  <div>
    <div class="page-section-alt" style="padding-bottom: 60px;">
      <news-banner :title="news.title" :date="news.dateCreated" />

      <v-container>
        <v-row>
          <v-col cols="12" md="8">
            <div id="newsText" v-html="news.text" />

            <!-- <v-img :src="news.previewImg" class="d-block mt-12" /> -->

            <CoolLightBox
              v-if="news.images && news.images.length > 0"
              :items="news.images"
              :index="galleryIndex"
              @close="galleryIndex = null"
            />

            <v-row v-if="news.images && news.images.length > 0" class="mt-3">
              <v-col
                cols="4"
                v-for="(img, i) in news.images"
                :key="i"
                class="d-flex child-flex"
              >
                <v-img
                  aspect-ratio="1"
                  @click="galleryIndex = i"
                  :src="img"
                  style="cursor: pointer;"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4">
            <h2 class="mb-6">Diğer Haberler</h2>
            <latest-news />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import NewsBanner from "./NewsBanner.vue";
import LatestNews from "./LatestNews.vue";
import fb from "@/plugins/firebase";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default Vue.extend({
  components: {
    NewsBanner,
    LatestNews,
    CoolLightBox
  },

  data() {
    return {
      news: {
        id: "",
        urlCode: "",
        title: "",
        dateCreated: new Date(),
        previewImg: "",
        text: "",
        images: []
      },

      galleryIndex: null
    };
  },

  methods: {
    async getNews(urlCode) {
      const query = await fb
        .firestore()
        .collection("/apps/kahev-org/press")
        .where("urlCode", "==", urlCode)
        .where("disabled", "==", false)
        .get();

      if (!query.empty) {
        const doc = query.docs[0];

        this.news.id = doc.id;
        this.news.urlCode = urlCode;
        this.news.title = doc.data().title;
        this.news.dateCreated = doc.data().dateCreated.toDate();
        this.news.previewImg = doc.data().previewImg;
        this.news.text = doc.data().text;
        this.news.images = doc.data().images;
      } else {
        this.$router.push({ name: "not-found" });
      }
    }
  },

  metaInfo() {
    const title = this.news.title;

    return {
      title: title
    };
  },

  async mounted() {
    await this.getNews(this.$route.params.urlCode);
  },

  async beforeRouteUpdate(to) {
    await this.getNews(to.params.urlCode);
  }
});
</script>

<style lang="scss"></style>
